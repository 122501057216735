@import 'style';

html,
body,
div#__next,
div#__next > div,
input {
	height: 100%;
	padding: 0;
	margin: 0;
	font-family: 'FlandersArtSans', BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

p {
	font-size: 15px;
	color: $secondary;
	white-space: pre-line;
}

h2 {
	font-size: 18px;
	color: $secondary;
	text-transform: uppercase;
}

* {
	box-sizing: border-box;
}
