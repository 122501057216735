@font-face {
	font-family: 'FlandersArtSans';
	src: url('/fonts/FlandersArtSans/FlandersArtSans-Light.woff2') format('woff2'),
		url('/fonts/FlandersArtSans/FlandersArtSans-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FlandersArtSans';
	src: url('/fonts/FlandersArtSans/FlandersArtSans-Regular.woff2')
			format('woff2'),
		url('/fonts/FlandersArtSans/FlandersArtSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FlandersArtSans';
	src: url('/fonts/FlandersArtSans/FlandersArtSans-Medium.woff2')
			format('woff2'),
		url('/fonts/FlandersArtSans/FlandersArtSans-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FlandersArtSans';
	src: url('/fonts/FlandersArtSans/FlandersArtSans-Bold.woff2') format('woff2'),
		url('/fonts/FlandersArtSans/FlandersArtSans-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
